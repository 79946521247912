export const resetDataLayer = () => import('./reset');
export const transactionEvent = () => import('./transaction');
export const productImpressionEvent = () => import('./ecommerce');
export const productClickEvent = () => import('./ecommerce');
export const productPageViewEvent = () => import('./ecommerce');
export const addToCartEvent = () => import('./ecommerce');
export const removeFromCartEvent = () => import('./ecommerce');
export const promotionImpressionEvent = () => import('./ecommerce');
export const promotionClickEvent = () => import('./ecommerce');
export const checkoutStepEvent = () => import('./ecommerce');
export const purchaseEvent = () => import('./ecommerce');
