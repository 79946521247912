export const getExistsCnpj = () => import('./get-exists-cnpj');
export const getExistsEmail = () => import('./get-exists-email');
export const getAppleId = () => import('./get-appleid');
export const getDataCnpj = () => import('./get-data-cnpj');
export const getUserIp = () => import('./get-user-ip');
export const confirmRegister = () => import('./confirm-register');
export const getPostalCodeLocation = () => import('./get-postal-code-location');
export const uploadOrganizationDocument = () => import('./upload-document');
export const resaleUploadDocument = () => import('./resale-upload-document');
export const getUser = () => import('./get-user');
export const getUserQuotations = () => import('./get-user-quotations');
export const saveUserQuotations = () => import('./save-user-quotations');
export const setIsLoadedRepresentateData = () => import('./loaded-representant-data');
